import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/home/Home.jsx";
import './App.css'


const App = () => {
  const [isAdmin, setIsAdmin]=useState(true);
  const [isLoggedIn, setIsLoggedIn]=useState(true);
  return (
    <>
      <Routes>
        <Route exact path="/" element={isLoggedIn && isAdmin ? <Home/> : <Navigate replace to={"/"} />} />
        
      </Routes>
    </>
  );
};

export default App;
